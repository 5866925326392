import React from "react"
import Package from "./Package"
import PropTypes from "prop-types"

const defaultPackages = [
  {
    title: "Budget (Individual)",
    subtitle: "$65 PER PERSON",
    content:
      "• 1 location, 30 minutes • All unedited photos • 5 Fully Edited Photos",
    image: "/img/senior.jpg",
  },
  {
    title: "Standard (Individual)",
    subtitle: "$120 PER PERSON",
    content:
      "• 3 locations, 1 hour • All unedited photos • 15 Fully Edited Photos • Photo Gallery to share with your family & friends",
    image: "/img/senior.jpg",
  },
  {
    title: "The Perfectionist",
    subtitle: "$180 PER PERSON",
    content:
      "• 3 locations, 1.5 hours • All unedited photos • 25 Fully Edited Photos • Photo Gallery to share with your family & friends",
    image: "/img/senior.jpg",
  },
  {
    title: "Couples (2 people)",
    subtitle: "$100 PER PERSON",
    content:
      "• 3 locations, 1.5 hours • All unedited photos • 10 Edited Photos each person + 5 Edited Photos together • Photo Gallery to share with your family & friends",
    image: "/img/senior.jpg",
  },
  {
    title: "Best Friends (2 people)",
    subtitle: "$100 PER PERSON",
    content:
      "• 3 locations, 1.5 hours • All unedited photos • 10 Edited Photos each person + 5 Edited Photos together • Photo Gallery to share with your family & friends",
    image: "/img/senior.jpg",
  },
  {
    title: "Group (3-7 people)",
    subtitle: "$85 PER PERSON",
    content:
      " 3 locations, 1.5-2.5 hours • All unedited photos • 10 Edited Photos each person + 5 Edited Photos together • Photo Gallery to share with your family & friends",
    image: "/img/seniorgroup.jpg",
  },
]

const PackagesSection = ({ packages, selectPackage }) => {
  let packageList
  let def = false
  if (packages && packages.length) {
    packageList = packages.map(item => {
      return {
        id: item.id,
        title: item.title,
        subtitle: item.subtitle,
        image: item.image.fluid.src,
        content: JSON.parse(item.features.raw),
        price: item.price,
        selectPackage: selectPackage,
        packageSlots: item.packageSlots,
      }
    })
  } else {
    def = true
    packageList = defaultPackages
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="mb4 text-center">
            <h4 className="uppercase mb16">Packages</h4>
          </div>
        </div>
        <div className="row">
          {packageList &&
            packageList.length &&
            packageList.map(pkg => <Package key={pkg.id} {...pkg} def={def} />)}
        </div>
      </div>
    </section>
  )
}
PackagesSection.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export default PackagesSection
